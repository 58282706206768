import { domReady, queue } from ".";
import autocomplete from "autocompleter";
import SlimSelect from "@cleverconnect/slim-select";
import { Option } from "@cleverconnect/slim-select/dist/data";
import { getMessage, getDefaultLang } from "../i18n";
import './searchOffers.css';
import { CcDefaultConfig } from "../shared";
export interface CcSearchOffersConfig extends CcDefaultConfig {
  lang: string
  job: {
    display: boolean,
    disableAutocomplete: boolean,
    defaultValue: string,
    placeholder: string,
    classes: string,
  },
  location: {
    display: boolean,
    searchByGeonameId: boolean,
    defaultValue: string,
    placeholder: string,
    classes: string,
  },
  contractType: {
    display: boolean,
    placeholder: string,
    classes: string,
  },
  industries: Array<string>,
  button: {
    displayIcon: boolean,
    displayText: boolean,
    text: string,
    color: string,
    bgColor: string,
    classes: string,
  },
  block: {
    font: string,
    color: string,
    displayStyle: number,
  }
  newTab: boolean,
};

export const ccSearchOffersDefaultConfig = {
  lang: getDefaultLang(),
  domain: '',
  job: {
    display: true,
    disableAutocomplete: false,
    defaultValue: '',
    placeholder: getMessage(getDefaultLang(), 'searchOffers.job.placeholder'),
    classes: 'cc-input',
  },
  location: {
    display: true,
    searchByGeonameId: false,
    defaultValue: '',
    placeholder: getMessage(getDefaultLang(), 'searchOffers.location.placeholder'),
    classes: 'cc-input',
  },
  contractType: {
    display: true,
    placeholder: getMessage(getDefaultLang(), 'searchOffers.contractType.placeholder'),
    classes: '',
  },
  industries: [],
  button: {
    displayIcon: true,
    displayText: true,
    text: getMessage(getDefaultLang(), 'searchOffers.button.text'),
    color: '',
    bgColor: '',
    classes: '',
  },
  block: {
    font: '',
    color: '',
    displayStyle: 1,
  },
  newTab: false,
} as CcSearchOffersConfig;

function isSearchBtnEnabled(jobInput: HTMLInputElement, locationInput: HTMLInputElement, displaySelect: SlimSelect, config: CcSearchOffersConfig ) {
   return jobInput?.value || locationInput?.value || displaySelect?.selected().length || config.job?.defaultValue || config.location?.defaultValue;
}

function display(element: HTMLElement, config: CcSearchOffersConfig): string {
  const color = (color: string) => `color:${color}`;
  const bgColor = (bgColor: string) => `background-color:${bgColor}`;
  const font = (font: string) => `font-family:${font}`;
  const blockStyles = [];
  config.block.font && blockStyles.push(font(config.block.font));
  config.block.color && blockStyles.push(color(config.block.color));
  const buttonStyles = [];
  config.button.color && buttonStyles.push(color(config.button.color));
  config.button.bgColor && buttonStyles.push(bgColor(config.button.bgColor));

  let job = '';
  let location = '';
  let contractType = '';
  let searchBtn = '';

  const icon = config.button.displayIcon ? `<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" class="svg-inline--fa fa-search fa-w-16" role="img" viewBox="0 0 512 512"
  height="14px" width="14px">
    <path fill="${config.button.color ? config.button.color : 'currentColor'}" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"/>
  </svg>` : '';
  const textButton = config.button.displayText ? config.button.text : `<span class="sr-only">${getMessage(getDefaultLang(), 'searchOffers.button.text')}</span>`;

  const jobClass = config.job.classes ? config.job.classes : '';
  const locationClass = config.location.classes ? config.location.classes : '';
  const searchClass = config.button.classes ? config.button.classes : '';

  if (config.block.displayStyle == 1) {
    job = config.job.display ? `<div style="flex: 1; vertical-align: top;" class="cc-column">
      <input type="text" class="cc-input-jobs ${jobClass}" placeholder="${config.job.placeholder}">
    </div>` : '';

    location = config.location.display ? `<div style="flex: 1; vertical-align: top;" class="cc-column">
      <input type="text" class="cc-input-locations ${locationClass}" placeholder="${config.location.placeholder}">
    </div>` : '';

    contractType = config.contractType.display ? `<div style="flex: 1; vertical-align: top;" class="cc-column">
      <select multiple class="cc-input-contract-types"></select>
    </div>` : '';

    searchBtn = `<div style="vertical-align: top; padding: 0 5px;">
    <button class="submit-btn cc-search-btn ${searchClass}" type="button" style="margin: 0px auto; border-style: none; padding: 0.5rem 2rem; ${buttonStyles.join(';')}">
        ${icon}
        ${textButton}
      </button>
    </div>`;

    return `
    <form id="cc-${element}-form" method="POST" action="" style="border-radius: 1rem;${blockStyles.join(';')}">
      <fieldset>
        <legend class="sr-only">${getMessage(getDefaultLang(), 'searchOffers.form.legend')}</legend>
        <div class="cc-div">
          <div class="cc-div1">
            ${job}
            ${location}
            ${contractType}
          </div>
          <div class="cc-div2">
            ${searchBtn}
          </div>
        </div>
      </fieldset>
    </form>
  `;

  } else {

    job = config.job.display ? `<div style="vertical-align: top; margin: auto;" class="cc-column">
      <label style="margin-top: 10px;">${getMessage(config.lang, 'searchOffers.job.label')} :</label>
      <input type="text" class="cc-input-jobs ${jobClass}" placeholder="${config.job.placeholder}">
    </div>` : '';

    location = config.location.display ? `<div style="margin-top: 10px; vertical-align: top; margin: auto;" class="cc-column">
      <label style="margin-top: 10px;">${getMessage(config.lang, 'searchOffers.location.label')} :</label>
      <input type="text" class="cc-input-locations ${locationClass}" placeholder="${config.location.placeholder}">
    </div>` : '';

    contractType = config.contractType.display ? `<div style="padding-top: 10px; vertical-align: top; margin: auto;" class="cc-column">
      <label for="multiple">${getMessage(config.lang, 'searchOffers.contractType.label')} :</label>
      <select multiple class="cc-input-contract-types"></select>
    </div>` : '';

    searchBtn = `<div style="padding-top: 10px; vertical-align: top; margin: auto;" class="cc-column">
    <button class="submit-btn cc-search-btn ${searchClass}" type="button" style="width: 100%; border-style: none; padding: 0.5rem 2rem; ${buttonStyles.join(';')}">
        ${icon}
        ${textButton}
      </button>
    </div>`;

  return `
    <form id="cc-${element}-form" method="POST" action="" style="border-radius: 1rem;${blockStyles.join(';')}">
      <fieldset>
        <legend class="sr-only">${getMessage(getDefaultLang(), 'searchOffers.form.legend')}</legend>
        <div style="margin-bottom: 10px;">
          ${job}
          ${location}
          ${contractType}
          ${searchBtn}
        </div>
      </fieldset>
    </form>
  `;

  }
}

export function searchOffers(container: string|HTMLElement, config: CcSearchOffersConfig = ccSearchOffersDefaultConfig) {
  const lang = getDefaultLang();
  config = {
    lang: config.lang ? config.lang : lang,
    domain: config.domain ? config.domain : ccSearchOffersDefaultConfig.domain,
    newTab: config.newTab ? config.newTab : ccSearchOffersDefaultConfig.newTab,
    job: {
      display: typeof config.job?.display === 'boolean' && config.job.display !== ccSearchOffersDefaultConfig.job.display ? config.job.display : ccSearchOffersDefaultConfig.job.display,
      disableAutocomplete: typeof config.job?.disableAutocomplete === 'boolean' && config.job.disableAutocomplete !== ccSearchOffersDefaultConfig.job.disableAutocomplete ? config.job.disableAutocomplete : ccSearchOffersDefaultConfig.job.disableAutocomplete,
      defaultValue: config.job?.defaultValue ? config.job.defaultValue : ccSearchOffersDefaultConfig.job.defaultValue,
      placeholder: config.job?.placeholder ? config.job.placeholder : getMessage(config.lang ? config.lang : lang, 'searchOffers.job.placeholder'),
      classes: config.job?.classes ? config.job.classes : ccSearchOffersDefaultConfig.job.classes,
    },
    location: {
      display: typeof config.location?.display === 'boolean' && config.location.display !== ccSearchOffersDefaultConfig.location.display ? config.location.display : ccSearchOffersDefaultConfig.location.display,
      searchByGeonameId: typeof config.location?.searchByGeonameId === 'boolean' && config.location.searchByGeonameId !== ccSearchOffersDefaultConfig.location.searchByGeonameId ? config.location.searchByGeonameId : ccSearchOffersDefaultConfig.location.searchByGeonameId,
      defaultValue: config.location?.defaultValue ? config.location.defaultValue : ccSearchOffersDefaultConfig.location.defaultValue,
      placeholder: config.location?.placeholder ? config.location.placeholder : getMessage(config.lang ? config.lang : lang, 'searchOffers.location.placeholder'),
      classes: config.location?.classes ? config.location.classes : ccSearchOffersDefaultConfig.location.classes,
    },
    contractType: {
      display: typeof config.contractType?.display === 'boolean' && config.contractType.display !== ccSearchOffersDefaultConfig.contractType.display ? config.contractType.display : ccSearchOffersDefaultConfig.contractType.display,
      placeholder: config.contractType?.placeholder ? config.contractType.placeholder : getMessage(config.lang ? config.lang : lang, 'searchOffers.contractType.placeholder'),
      classes: config.contractType?.classes ? config.contractType.classes : ccSearchOffersDefaultConfig.contractType.classes,
    },
    industries: typeof config.industries === 'object' && config.industries?.length ? config.industries : ccSearchOffersDefaultConfig.industries,
    button: {
      displayIcon: typeof config.button?.displayIcon === 'boolean' && config.button.displayIcon !== ccSearchOffersDefaultConfig.button.displayIcon ? config.button.displayIcon : ccSearchOffersDefaultConfig.button.displayIcon,
      displayText: typeof config.button?.displayText === 'boolean' && config.button.displayText !== ccSearchOffersDefaultConfig.button.displayText ? config.button.displayText : ccSearchOffersDefaultConfig.button.displayText,
      text: config.button?.text ? config.button.text : getMessage(config.lang ? config.lang : lang, 'searchOffers.button.text'),
      color: config.button?.color ? config.button.color : ccSearchOffersDefaultConfig.button.color,
      bgColor: config.button?.bgColor ? config.button.bgColor : ccSearchOffersDefaultConfig.button.bgColor,
      classes: config.button?.classes ? config.button.classes : ccSearchOffersDefaultConfig.button.classes,
    },
    block: {
      font: config.block?.font ? config.block.font : ccSearchOffersDefaultConfig.block.font,
      color: config.block?.color ? config.block.color : ccSearchOffersDefaultConfig.block.color,
      displayStyle: config.block?.displayStyle ? config.block.displayStyle : ccSearchOffersDefaultConfig.block.displayStyle,
    },
  };

  domReady(function() {
    queue.enqueue(async () => {
      if (container) {
        const element = typeof container === 'string' ? document.getElementById(container) : container;
        if (element) {
          element.innerHTML = display(element.id, config);

          const jobInput = <HTMLInputElement>document.getElementsByClassName('cc-input-jobs')[0];
          if (jobInput) {
            jobInput.value = config.job.defaultValue;
          }
          const locationInput = <HTMLInputElement>document.getElementsByClassName('cc-input-locations')[0];
          if (locationInput) {
            locationInput.value = config.location.defaultValue;
          }
          let locationInputGeonameId: string;

          const buttonSubmit = <HTMLButtonElement>document.getElementsByClassName('cc-search-btn')[0];
          let displaySelect: SlimSelect;

          if (config.job.display) {
            if (!config.job.disableAutocomplete) {
              autocomplete({
                input: jobInput,
                fetch: async function(text, update) {
                    text = text.toLowerCase();
                    const response = await fetch(`${config.domain}/api/jobs/search?query=${text}`, { credentials: 'include' });
                    const suggestions = await response.json();
                    update(suggestions);
                },
                onSelect: function(item: any) {
                    jobInput.value = item.label;
                },
                disableAutoSelect: true
              });
            }

            jobInput.addEventListener('change', () => {
              if (isSearchBtnEnabled(jobInput, locationInput, displaySelect, config)) {
                buttonSubmit.removeAttribute('disabled');
                buttonSubmit.style.cursor = 'pointer';
              } else {
                buttonSubmit.setAttribute('disabled', 'disabled');
                buttonSubmit.style.cursor = 'not-allowed';
              }
            });
          }

          if (config.location.display) {
            autocomplete({
              input: locationInput,
              fetch: async function(text, update) {
                  text = text.toLowerCase();
                  const response = await fetch(`${config.domain}/api/autocomplete/location?query=${text}`, { credentials: 'include' });
                  const suggestions = await response.json();
                  const data = suggestions.interpretations.map(interpretation => ({
                    label: interpretation.feature.name,
                    value: interpretation.feature.id.split(':')[1],
                }));
                update(data);
              },
              onSelect: function(item: any) {
                locationInput.value = item.label;
                if (config.location.searchByGeonameId) {
                  locationInputGeonameId = item.value;
                }
              },
              disableAutoSelect: true
            });

            locationInput.addEventListener('change', () => {
              if (isSearchBtnEnabled(jobInput, locationInput, displaySelect, config)) {
                buttonSubmit.removeAttribute('disabled');
                buttonSubmit.style.cursor = 'pointer';
              } else {
                buttonSubmit.setAttribute('disabled', 'disabled');
                buttonSubmit.style.cursor = 'not-allowed';
              }
            });
          }

          if (config.contractType.display) {
            displaySelect = new SlimSelect({
              select: '.cc-input-contract-types',
              showSearch: false,
              closeOnSelect: false,
              allowDeselectOption: true,
              placeholder: config.contractType.placeholder,
              onChange: (info: Option[]) => {
                if (jobInput?.value || locationInput?.value || info.length ||
                    config.job.defaultValue || config.location.defaultValue) {
                  buttonSubmit.removeAttribute('disabled');
                  buttonSubmit.style.cursor = 'pointer';
                } else {
                  buttonSubmit.setAttribute('disabled', 'disabled');
                  buttonSubmit.style.cursor = 'not-allowed';
                }
              }
            })

            await fetch(`${config.domain}/api/contract-types`, { credentials: 'include' })
            .then(function (response) {
              return response.json()
            })
            .then(function (json) {
              for (let i = 0; i < json.length; i++) {
                displaySelect.addData({value: json[i].meteojobId ,text: json[i].value});
              }
            })
          }

          if (!isSearchBtnEnabled(jobInput, locationInput, displaySelect, config)) {
            buttonSubmit.setAttribute('disabled', 'disabled');
            buttonSubmit.style.cursor = 'not-allowed';
          } else {
            buttonSubmit.removeAttribute('disabled');
            buttonSubmit.style.cursor = 'pointer';
          }

          buttonSubmit.addEventListener('click', () => {
            let query: string[] = [];
            if (jobInput?.value) {
              query.push(`what=${jobInput.value}`);
            } else if (config.job.defaultValue) {
              query.push(`what=${config.job.defaultValue}`);
            }

            if (locationInput?.value) {
              if (config.location.searchByGeonameId) {
                query.push(`location=${locationInputGeonameId}`);
              } else {
                query.push(`where=${locationInput.value}`);
              }
            } else if (config.location.defaultValue) {
              query.push(`where=${config.location.defaultValue}`);
            }

            if (displaySelect?.selected().length) {
              const ct = <string[]> displaySelect.selected();
              query.push(`contracts=${ct.join(',')}`);
            }

            if (config.industries.length) {
              config.industries.forEach(industry => query.push(`industry=${industry}`))
            }

            window.open(`${config.domain}/jobsearch/offers?${query.join('&')}`, config.newTab ? '_blank' : '_self');
          });
        }
      }
    });
  });
}
